<template>
    <div class="product_container">
        <h2>相关产品推荐</h2>
        <div
            class="recommSetImg animated bounceInLeft"
            v-for="item in productsList"
            :key="item.id"
        >
            <img class="recomm_img" :src="item.productImg" alt="产品推荐"/>
            <p class="recomm_small sim-button button5" @click="clickMenu(item)">
                {{ item.name }}</p>
        </div>
    </div>
</template>
<script>
import demand from '../../api/data.js'

export default {
    inject: ['reload'],
    /**
     * param productImgList 产品列表
     */
    props: ['productResourch'],
    data() {
        return {
            productImgList: [],
            productsList: [

            ]
        }
    },
    watch: {
        productResourch: function () {
            this.productImgList = this.productResourch
        }
    },
    methods: {
        // 获取产品列表
        getProdctResource() {
            demand.home.getProductResource().then((res) => {
                if (res.code == 200) {
                    this.productImgList = res.data
                }
                // console.log(res.data);
            })
        },
        //按钮点击事件
        clickMenu(item) {
            let clickMenuName = item.component
            let currentMenu = this.$store.state.pubInfo.currentMenu
            if (clickMenuName !== currentMenu) {
                this.$store.commit('setCurrentMenu', clickMenuName)
                this.reload();
                this.$router.push({name: clickMenuName})
            }
        },
    }
}
</script>
<style lang="less" scoped>
.product_container {
    width: 1630px;
    margin-left: 165px !important;
    text-align: center !important;
    // padding: 0 10px;
    h2 {
        color: #666;
        //letter-spacing: 2px;
        text-align: center;
    }

    .recommSetImg {
        float: left;
        width: 19%;
        height: 240px;
        margin: 0 0 0 15px;
        position: relative;
        background: #fff;
        text-align: center;
        padding: 20px 0;

        .recomm_small {
            width: 80%;
            font-size: 16px;
            color: #2db5a3;
            text-align: center;
            padding: 10px 0 !important;
        }

        .recomm_img {
            width: 200px;
            height: 200px;
            cursor: pointer;
            //   display: block;
            //   margin: 0 auto;
            transition: all 0.6s;
        }

        .recomm_img:hover {
            position: relative;
            transform: scale(1.4);
            z-index: 100;
        }
    }

    .sim-button {
        line-height: 20px;
        height: 20px;
        margin-top: 10px;
    }
}

/* 小屏幕（平板，大于等于 768px） */
@media (max-width: 768px) {
    .product_container {
        height: 169.75rem;
        position: relative;

        h2 {
            font-size: 5.75rem;
            font-weight: bold;
            color: #4b4646;
            //letter-spacing: 0.125rem;
        }

        .recommSetImg {
            width: 96%;
            height: 29rem;
            margin-left: 0rem;
            border: 1px solid #2db5a3;

            .recomm_small {
                font-size: 3.875rem;
                width: 56%;
            }

            .recomm_img {
                float: left;
                width: 28.5rem;
                height: 28.5rem;
                padding-left: 5rem;
            }
        }

        .sim-button {
            line-height: 6.25rem;
            height: 7.25rem;
            margin-top: 8.625rem;
            width: 45rem;
            font-weight: bold;
            margin-left: 38rem;
            //  border: 1px solid red;
            background: rgb(196, 207, 211);
            // border-radius: 1rem;
        }
    }
}
</style>
